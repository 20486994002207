<template>
  <b-modal
    id="modal-create-event-type"
    ref="modal-create-event-type"
    centered="centered"
    :title="(eventType.id ? 'Modifier' : 'Ajouter') + ` un type d'évènement`"
    @ok="create"
  >
    <div v-if="isCreatingEventType">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formCreateEventType">
      <b-row>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group 
            label="Libellé *" 
            label-for="Libellé" 
            class="pr-1" 
            style="flex: 1"
          >
            <validation-provider 
              #default="{ errors }" 
              name="Libellé" 
              rules="required"
            >
              <ejs-textbox 
                id="socialReason" 
                v-model="eventType.label" 
                placeholder="Libellé *"
              >
              </ejs-textbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Couleur"
            label-for="color"
            class="d-flex flex-column align-content-center align-items-center"
          >
            <validation-provider #default="{ errors }" name="Color">
              <ejs-colorpicker
                id="color"
                v-model="eventType.color"
                mode="Palette"
              ></ejs-colorpicker>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Description" label-for="description">
            <validation-provider #default="{ errors }" name="Description">
              <b-form-textarea
                id="description"
                v-model="eventType.description"
                :state="errors.length &gt; 0 ? false : null"
                rows="3"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-for="payrollEvent">
            <ejs-dropdownlist 
              id="payrollEvent" 
              v-model="eventType.payrollEventId" 
              :dataSource="payrollEventsList" 
              :fields="{ text: 'label', value: 'id' }" 
              placeholder="Evènement de paie"
              name="modal-create-event-type"
            >
            </ejs-dropdownlist>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-for="isDependent"
            ><span
              >Ce type d&apos;évènement est-il dépendant d'une affaire?</span
            >
            <b-form-checkbox
              class="float-right mr-0"
              id="isDependent"
              checked="false"
              v-model="eventType.isDependent"
              switch="switch"
              inline="inline"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-for="isEditable" class="w-100">
            <span
              >Ce type d&apos;évènement est-il modifiable par un tiers ?</span
            >
            <b-form-checkbox
              class="float-right mr-0"
              id="isEditable"
              checked="false"
              v-model="eventType.isEditable"
              switch="switch"
              inline="inline"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row> </validation-observer
    ><template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button
          class="mr-1 float-left"
          v-if="eventType.id && archive"
          variant="outline-danger"
          @click="deleteEventTypeLocal(eventType.id, eventType.label)"
          ><feather-icon icon="TrashIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="eventType.id && !archive"
          variant="outline-danger"
          @click="archiveEventTypesLocal(eventType.id, eventType.label)"
          ><feather-icon icon="ArchiveIcon"
        /></b-button>
        <b-button
          class="float-left"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-if="eventType.id && archive"
          variant="outline-warning"
          @click="restoreEventTypeLocal(eventType.id, eventType.label)"
          ><feather-icon icon="ArrowDownIcon"
        /></b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{
          eventType.id ? "Modifier" : "Ajouter"
        }}</b-button>
        <b-button
          class="float-right mr-1"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      eventType: {
        label: "",
        color: "#0c3571",
        isDependent: false,
        isEditable: false,
        description: "",
        payrollEventId: null,
      },
    };
  },
  props: {
    archive: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getPayrollEvents({});
  },
  methods: {
    popupCreateEventTypeEvent(id) {
      if (id > 0) {
        this.getEventTypeById({
          eventTypeId: id,
        }).then((res) => {
          this.eventType = res;
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.eventType = {
        label: "",
        color: "#0c3571",
        isDependent: false,
        isEditable: false,
        description: "",
      };
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formCreateEventType.validate().then((success1) => {
        if (success1) {
          if (this.eventType.id) {
            this.updateEventType({ eventType: this.eventType }).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-event-type");
                this.$emit("refreshGrid");
              });
            });
          } else {
            this.createEventType({
              eventType: this.eventType,
            }).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-event-type");
                this.$emit("refreshGrid");
              });
            });
          }
        }
      });
    },
    archiveEventTypesLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce type d'évènement en consultant la liste de vos archives.",
          {
            title:
              "Êtes-vous sûr de vouloir archiver le type d'évènement \"" +
              label +
              '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveEventTypes({
              eventTypeIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-event-type");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    restoreEventTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez ré-utiliser ce type d'évènement dans tous les modules de votre application",
          {
            title:
              "Êtes-vous sûr de vouloir restaurer le type d'évènement \"" +
              label +
              '" ?',
            size: "sm",
            okVariant: "warning",
            okTitle: "Restaurer",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.restoreEventTypes({
              eventTypeIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-event-type");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    deleteEventTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir supprimer le type d'évènement \"" +
            label +
            '" ?',
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteEventTypes({
              eventTypeIds: [id],
            }).then((res) => {
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-event-type");
                this.$emit("refreshGrid");
              });
            });
          }
        });
    },
    ...mapActions([
      "createEventType",
      "getPayrollEvents",
      "updateEventType",
      "getEventTypeById",
      "archiveEventTypes",
      "restoreEventTypes",
      "deleteEventTypes",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingEventType", "payrollEventsList"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
};
</script>

<style></style>
