<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingEventTypesList || isLoadingEventTypesListArchived || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des types d'évènements...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton")
  popup-create-event-type(:archive="archive" ref="popupCreateEventType" @refreshGrid="refreshGrid")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import PopupCreateEventType from "@/components/planning/planning-settings/popup-planning-settings/PopupCreateEventType";

export default {
  components: {
    PopupCreateEventType,
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "eventType",
      uid: "eventTypes",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher un type d'évènement",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: false,
      },
      {
        type: "string",
        field: "color",
        headerText: "Couleur",
        width: 60,
        maxWidth: 60,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        template: "colorTemplate",
        visible: true,
        unavailableExport: true,
      },
      {
        type: "string",
        field: "label",
        headerText: "Nom",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "description",
        headerText: "Description",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "stripHtmlTemplate",
        filter: { type: "Menu", operator: "contains" },
      },
      {
        type: "number",
        field: "payrollEventId",
        headerText: "Variable de paie",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: false,
        showInColumnChooser: true,
        visible: true,
        template: "payrollEventTemplate",
      },
      {
        type: "boolean",
        field: "isDependent",
        headerText: "Indépendant",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        displayAsCheckBox: true,
        template: "booleanTemplate",
      },
      {
        type: "boolean",
        field: "isEditable",
        headerText: "Editable",
        width: 120,
        minWidth: 120,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        template: "booleanTemplate",
      },
    ];
  },
  computed: {
    ...mapGetters([
      "eventTypesList",
      "eventTypesListArchived",
      "isLoadingEventTypesList",
      "isLoadingEventTypesListArchived",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters[this.name + "sListArchived"];
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
  },
  created() {
    this.getEventTypes({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions(["getEventTypes", "getEventTypesArchived"]),
    formatCurrency,
    setColumnsByObject,
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.popupCreateEventType("");
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getEventTypesArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getEventTypes({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.popupCreateEventType(event.rowData.id);
    },
    popupCreateEventType(id) {
      this.$bvModal.show("modal-create-event-type");
      this.$refs["popupCreateEventType"].popupCreateEventTypeEvent(id);
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    setTagsSelected(val) {},
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
  },
};
</script>
